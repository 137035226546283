/* eslint-disable arrow-body-style */
import React from 'react'
import Table from 'react-bootstrap/Table';
import { useLocation, useParams } from 'react-router-dom';

const Detail = () => {

    const location = useLocation();
    const { id } = useParams();

    const receivedData = location?.state?.data || 'No data received';
    const filteData = receivedData?.find((e) => e.id === Number(id))
    return (
        <>
            <section>
                <div className='container'>
                    <div className='Submitter'>
                        <h1 className='mb-5'>Submitter Information</h1>
                        <Table striped bordered hover variant="light" className='d-flex w-100'>
                            <thead>
                                <tr>
                                    <th>Notice Id</th>
                                </tr>
                                <tr>
                                    <th>Business Name</th>

                                </tr>
                                <tr>
                                    <th>First Name
                                    </th>

                                </tr>
                                <tr>
                                    <th>Last Name</th>


                                </tr>
                                <tr>
                                    <th>Role</th>


                                </tr>
                                <tr>
                                    <th>Email</th>



                                </tr>
                                <tr>
                                    <th>Signature</th>


                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{filteData.id}</td>
                                </tr>
                                <tr>
                                    <td>{filteData.submitter_business_name}</td>
                                </tr>
                                <tr>
                                    <td>{filteData.submitter_first_name}</td>

                                </tr>
                                <tr>
                                    <td>{filteData.submitter_last_name}</td>

                                </tr>
                                <tr>
                                    <td>{filteData.submitter_role}</td>


                                </tr>
                                <tr>
                                    <td>{filteData.submitter_email}</td>


                                </tr>
                                <tr>
                                    <td>
                                        <img src={filteData.signature} alt="Signature" className='img-fluid' /></td>
                                </tr>

                            </tbody>
                        </Table>
                    </div>
                    <hr />
                    <div className='Business my-5'>
                        <h1 className=''>Business Information</h1>
                        <div className='w-100'>
                            <Table striped bordered hover variant="light" className=''>
                                <thead>
                                    <tr>
                                        <th>Business Name</th>
                                        <th>EIN Number
                                        </th>
                                        <th>State of Formation</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{filteData.business_information.business_name}</td>
                                        <td>{filteData.business_information.ein_number}</td>
                                        <td>{filteData.business_information.state_of_formation}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <h5>Physical Address</h5>
                                <Table striped bordered hover variant="light" className='d-flex w-100'>
                                    <thead>
                                        <tr>
                                            <th>Street Address</th>


                                        </tr>
                                        <tr>
                                            <th>Street Address Line 2</th>



                                        </tr>
                                        <tr>
                                            <th>City</th>
                                        </tr>
                                        <tr>
                                            <th>State</th>
                                        </tr>
                                        <tr>
                                            <th>Zip Code</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{filteData.business_information.physical_street_address}</td>


                                        </tr>
                                        <tr>
                                            <td>{filteData.business_information.physical_street_address_line_2 ? filteData.business_information.physical_street_address_line_2 : "Nill"}</td>


                                        </tr>
                                        <tr>
                                            <td>{filteData.business_information.physical_city}</td>
                                        </tr>
                                        <tr>
                                            <td>{filteData.business_information.physical_state}</td>
                                        </tr>
                                        <tr>
                                            <td>{filteData.business_information.mailing_zip_code}</td>
                                        </tr>

                                    </tbody>
                                </Table>
                            </div>
                            <div className='col-md-6'>
                                <h5>Mailing Address</h5>
                                <Table striped bordered hover variant="light" className='d-flex w-100'>
                                    <thead>
                                        <tr>
                                            <th>Street Address</th>


                                        </tr>
                                        <tr>
                                            <th>Street Address Line 2</th>



                                        </tr>
                                        <tr>
                                            <th>City</th>
                                        </tr>
                                        <tr>
                                            <th>State</th>
                                        </tr>
                                        <tr>
                                            <th>Zip Code</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{filteData.mailing_street_address ? filteData.mailing_street_address : "Same as Physical"}</td>


                                        </tr>
                                        <tr>
                                            <td>{filteData.mailing_street_address_line_2 ? filteData.mailing_street_address_line_2 : "Same as Physical"}</td>


                                        </tr>
                                        <tr>
                                            <td>{filteData.mailing_city ? filteData.mailing_city : "Same as Physical"}</td>
                                        </tr>
                                        <tr>
                                            <td>{filteData.mailing_state ? filteData.mailing_state : "Same as Physical"}</td>
                                        </tr>
                                        <tr>
                                            <td>{filteData.mailing_zip_code ? filteData.mailing_zip_code : 'Same as Physical'}</td>
                                        </tr>

                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='ShareHolder my-5'>
                        <h1 className=''><span className='fs-2'>{filteData?.business_information?.share_holders.length}</span> - ShareHolders Information </h1>
                        {filteData?.business_information?.share_holders?.map((e) => {
                            return (
                                <>
                                    <Table striped bordered hover variant="light" className='d-flex w-100 mb-5'>
                                        <thead>
                                            <tr>
                                                <th>First Name</th>
                                            </tr>
                                            <tr>
                                                <th>Last Name</th>

                                            </tr>
                                            <tr>
                                                <th>Street Address
                                                </th>

                                            </tr>
                                            <tr>
                                                <th>Street Address Line 2</th>


                                            </tr>
                                            <tr>
                                                <th>City</th>


                                            </tr>
                                            <tr>
                                                <th>State</th>



                                            </tr>
                                            <tr>
                                                <th>Zip Code</th>
                                            </tr>
                                            <tr>
                                                <th>Ownership</th>
                                            </tr>
                                            <tr>
                                                <th>Date of Birth</th>
                                            </tr>
                                            <tr>
                                                <th>Social Security Number</th>
                                            </tr>
                                            <tr>
                                                <th>Identity Type</th>
                                            </tr>
                                            <tr>
                                                <th>Identification Number</th>
                                            </tr>
                                            <tr>
                                                <th>Identification Expiration Date</th>
                                            </tr>
                                            <tr>
                                                <th>State of Issuance</th>
                                            </tr>

                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td>{e.shareholder_first_name}</td>
                                            </tr>
                                            <tr>
                                                <td>{e.shareholder_last_name}</td>
                                            </tr>
                                            <tr>
                                                <td>{e.shareholder_street_address}</td>

                                            </tr>
                                            <tr>
                                                <td>{e.shareholder_street_address_line_2 ? e.shareholder_street_address_line_2 : "Nill"}</td>
                                            </tr>
                                            <tr>
                                                <td>{e.shareholder_city}</td>
                                            </tr>
                                            <tr>
                                                <td>{e.shareholder_state}</td>
                                            </tr>
                                            <tr>
                                                <td>{e.shareholder_zip_code}</td>
                                            </tr>
                                            <tr>
                                                <td>{e.ownership_percentage}</td>
                                            </tr>
                                            <tr>
                                                <td>{e.date_of_birth}</td>
                                            </tr>
                                            <tr>
                                                <td>{e.social_security_number}</td>
                                            </tr>
                                            <tr>
                                                <td>{e.identification_type}</td>
                                            </tr>
                                            <tr>
                                                <td>{e.identification_number}</td>
                                            </tr>
                                            <tr>
                                                <td>{e.identification_expiration_date}</td>
                                            </tr>
                                            <tr>
                                                <td>{e.state_of_issuance}</td>
                                            </tr>

                                        </tbody>
                                    </Table>
                                    <div className='row mb-5'>
                                        <div className='col-md-6'>
                                            <h4>Front of Identification Image</h4>
                                            <img src={e.front_image} alt='Front Identification' className='img-fluid' />
                                        </div>
                                        <div className='col-md-6'>
                                            <h4>Back of Identification Image</h4>
                                            <img src={e.back_image} alt='Back Identification' className='img-fluid' />
                                        </div>
                                    </div>
                                    <hr />
                                </>
                            )
                        }
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Detail